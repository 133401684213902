export enum MessengerFilterTabsNames {
  All = "All",
  Unread = "Unread",
}

export const enum MessageType {
  MARKETING = "Marketing",
}

export const enum NotificationMedium {
  SMS = "sms",
  EMAIL = "email",
}

export const enum MessageDirection {
  INBOUND = "Inbound",
  OUTBOUND = "Outbound",
}

export enum WebsocketMessengerMessageType {
  CONVERSATION_READ = "conversationRead",
  INBOUND_SMS = "inboundSms",
  OUTBOUND_SMS = "outboundSms",
  PING = "ping",
  SMS_STATUS_CALLBACK = "smsStatusCallback",
}

export const enum MessengerQueryKeys {
  CONVERSATIONS_LIST = "conversations-list",
  CUSTOMER_MESSAGES = "customer-messages",
  MARK_CONVERSATION_AS_READ = "mark-conversation-as-read",
  SEND_SMS_MESSAGE = "send-sms-message",
  UNREAD_CONVERSATIONS_COUNT = "unread-conversations-count",
  SEND_BULK_MESSAGE = "send-bulk-message",
  MESSENGER_WEBHOOK_CONNECTION_URL = "messenger-webhook-connection-url",
  MESSAGE_MEDIA = "messenger-message-media",
}

export const enum MessengerSendMessageFormField {
  MESSAGE_DETAILS = "messageDetails",
  MEDIA = "media",
  QUERY = "query",
}

export const PER_PAGE_SIZE_LIMIT = 50;
export const ONE_MESSAGE_CHARACTERS_LIMIT = 160;
export const MESSENGER_DEFAULT_PAGE_NUMBER = 1;
export const MARK_CONVERSATION_AS_READ_DELAY_MS = 2000;
