import {AxiosPromise} from "axios";

import httpClient from "~api/httpClient";
import {CustomerSegmentQuery} from "~types/customers/customerSegment";
import {
  MessengerConversationItem,
  MessengerMessage,
  MessengerMessageMediaAttachment,
  MessengerUnreadConversationsCountType,
} from "~types/messenger";

export interface FetchConversationsParams {
  unread?: boolean;
  q?: string;
  page?: number;
  perPage?: number;
}

export interface FetchConversationMessagesParams {
  page?: number;
  perPage?: number;
}

export interface SendingMessageDetails {
  content: string;
}

export interface MessengerPaginationOutput<T> {
  records: T[];
  meta: {
    total: number;
    currentPage: number;
    hasNextPage: boolean;
  };
}

export interface SendBulkMessageBody {
  query?: CustomerSegmentQuery;
  centsCustomerIds?: number[];
  messageDetails: {
    content: string;
  };
}

export interface MessengerFailedSendingReason {
  centsCustomerId: number;
  reason?: string;
}
export interface SendBulkMessageResponse {
  successful: number;
  failed: MessengerFailedSendingReason[];
}

export type FetchConversationsResponse =
  MessengerPaginationOutput<MessengerConversationItem>;

export type FetchConversationMessagesResponse =
  MessengerPaginationOutput<MessengerMessage>;

export type FetchMessengerConnectionUrlResponse = {url: string};

export const fetchMessengerConnectionUrl =
  (): AxiosPromise<FetchMessengerConnectionUrlResponse> => {
    return httpClient({
      method: "GET",
      url: "websocket-connection/messenger",
    });
  };

export const fetchConversations = ({
  params,
}: {
  params: FetchConversationsParams;
}): AxiosPromise<FetchConversationsResponse> => {
  return httpClient({
    method: "GET",
    url: "messenger/conversations",
    params,
  });
};

export const fetchUnreadConversationsCount =
  (): AxiosPromise<MessengerUnreadConversationsCountType> => {
    return httpClient({
      method: "GET",
      url: "messenger/conversations/unread-count",
    });
  };

export const fetchConversationMessages = (
  centsCustomerId: string,
  params: FetchConversationMessagesParams
): AxiosPromise<FetchConversationMessagesResponse> => {
  return httpClient({
    method: "GET",
    url: `messenger/conversations/${centsCustomerId}`,
    params,
  });
};

export const markConversationAsRead = (
  centsCustomerId: string
): AxiosPromise<MessengerConversationItem> => {
  return httpClient({
    method: "PATCH",
    url: `messenger/conversations/${centsCustomerId}/read`,
  });
};

export const sendSmsMessage = (centsCustomerId: string, body: FormData): AxiosPromise => {
  return httpClient({
    method: "POST",
    url: `messenger/conversations/${centsCustomerId}/message`,
    data: body,
  });
};

export const sendBulkMessage = (
  body: FormData
): AxiosPromise<SendBulkMessageResponse> => {
  return httpClient({
    method: "POST",
    url: "messenger/conversations/bulk-message",
    data: body,
  });
};

export const fetchMessageMedia = (
  messageId: MessengerMessage["messageId"]
): AxiosPromise<MessengerMessageMediaAttachment[]> => {
  return httpClient({
    method: "GET",
    url: `messenger/messages/${messageId}/media`,
  });
};
